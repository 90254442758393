import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "医療ヘルスケアベンチャーで開発チームと Sync した知財部門を立ち上げた話",
  "date": "2021-12-03T09:05:44.000Z",
  "slug": "entry/2021/12/03/180544",
  "tags": ["medley"],
  "hero": "./2021_12_03.png",
  "heroAlt": "長期的な特許活動のあり方"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは、メドレーのコーポレート本部法務コンプライアンス部で、知的財産関連の業務を担当している鬼鞍です。`}</p>
    <p>{`1 年ほど前に本ブログで「`}<a parentName="p" {...{
        "href": "/entry/2020/05/22/173902"
      }}>{`特許とはなにか`}</a>{`」というテーマで当社における特許啓蒙活動を紹介させて頂いたのですが、思っていた以上に反響があり、「面白い」とか「わかりやすい」という嬉しいコメントも頂戴しました。`}</p>
    <p>{`私がメドレーに入社して最初のミッションが知財部門の立ち上げだったので、次はぜひ`}<strong parentName="p">{`「ベンチャーでの知財部門の立ち上げ方」`}</strong>{`的なテーマでブログ発信したいと思っていたのですが、当初はまだ具体的な社内事例が少なかったため、実績ができた段階で改めて本ブログで発表したいと思っていました。`}</p>
    <p>{`それから１年以上が経過して実際に特許も取得するなど知財活動としての実績が蓄積されてきたため、これを機会に`}<strong parentName="p">{`ベンチャーで知財部門を立ち上げる際にやるべきことについて、実例に触れながら具体的にまとめてみました。`}</strong></p>
    <p>{`かなり長いコンテンツになってしまいましたが、`}<strong parentName="p">{`フェーズごとの知財活動計画`}</strong>{`であったり、`}<strong parentName="p">{`知財ロードマップの作り方`}</strong>{`など、`}<strong parentName="p">{`なかなか表に出てこない知財部門立ち上げストーリーの具体的事例を惜しみなく書かせていただいております`}</strong>{`ので、ぜひご一読いただき、お役に立てれば幸いです。`}</p>
    <h1>{`知財は開発チームとの信頼関係の構築がまず第一`}</h1>
    <p>{`先ほどは知財部門を立ち上げストーリの中で、知財活動計画とか、知財ロードマップであるとか少し格好つけたような文字が羅列していましたが、実は私が知財専任として初めに配置されて最初にやったことはそんな大それたことではありませんでした。`}</p>
    <p>{`最初になにをやったかというと、`}<strong parentName="p">{`エンジニアの方とランチに行く、あるいは飲みに行く`}</strong>{`、ということでした。当時はまだ Covid-19 が蔓延する前だったので気軽にそういうことができました。入社して最初の 1 ヶ月くらいは週 2 回くらいのペースで誰かとランチか飲みにいくということをしていたと思います。`}</p>
    <p>{`飲みに行く、というとふざけているとか思われるかもしれませんが、知財部門の立ち上げストーリーを語る上で、このプロセスは無視できないほど重要だと考えています。先に断っておくと私は酒好きでもなければ、家でも一切飲みません。`}</p>
    <p>{`当たり前のことなのですが、知財というのはプロダクトを生み出す開発組織があって初めて価値を発揮するものです。知財はあくまで事業や開発をサポートするところであって、それ単体で存在意義を発揮することはほぼありません。特に特許活動については開発チームとの連携が非常に重要です。`}</p>
    <p>{`一緒にご飯を食べることが信頼関係を構築する上で最良の方法、とまでは言いませんが、少なくともご飯を食べない人はいないわけで、時間を無駄にしている感もなければ、オフィスの外で会えば仕事以外のことを話題にし易いので互いの趣味嗜好を知ることができます。みなさんもそうだと思いますが、通常、仲の悪い人とは一緒に食事はしないと思います。`}</p>
    <p>{`特に、この後にもご紹介する`}<strong parentName="p">{`社内のアイデアを発掘するという発明発掘活動は、話しやすいカジュアルな雰囲気で雑談のような感じでブレストするというのが非常に重要`}</strong>{`で、このときに相手がどんな人間かを互いに知っているか、知らないかでは大きな差がでてきます。`}</p>
    <p>{`また更に、この後にご紹介する知財計画立案や知財ロードマップを作成することも当然重要なのですが、結局仕事というのは人と人との間を思考がやりとりされて実現化されていくものなので、人間関係・信頼関係が全ての基礎であり、実は一番大切にするべきものだと思います。`}</p>
    <p>{`これは何も知財に限ったことではなく、部門を跨いで仕事をする上で信頼関係は非常に重要ですし、知財の観点で見ても`}<strong parentName="p">{`円滑なコミュニケーションが結果的にいい発明やアイデアを生み出すための土壌づくりに貢献する`}</strong>{`ものだと考えています。`}</p>
    <h1>{`知財活動計画は、事業の成長フェーズに合わせて設定する`}</h1>
    <p>{`知財部門の設立にあたって、エンジニアとのコミュニケーションと並行して進めたのが、知財活動計画の立案でした。知財活動とは、知財サイクルをうまく事業サイクルにインストールしてサイクルを循環させることであり、更にはこのサイクルが循環するための運用基盤を構築することを指します。また、ここでいう知財サイクルとは、例えば、社内に埋もれたアイデアを発掘し（創発）→ それを知財権化し（保護）→ 権利化した知財を活用し（活用）→ 活用した知財で得られた利益や結果を創発支援に適用できる形に変換する、というものです。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120413.jpg",
      "alt": "20211126120413.jpg"
    }}></img>
    <p>{`このような知財サイクルを、何の計画や戦略もなしに事業サイクルへインストールしようとするとうまくいきません。なぜなら、事業というのは日々成長し変化していくもので、`}<strong parentName="p">{`事業の成長に応じて知財活動の目的や課題も変化するからです`}</strong>{`。`}</p>
    <p>{`例えば、子会社が増えて事業規模が拡大すると、その子会社の事業領域で既に多くの特許出願がされている場合には、発明発掘活動よりもまずは法的リスクを回避することを最優先にして、他社の特許調査をしっかりやってからプロダクトをリリースする仕組みを作ろう、ということになります。あるいは、事業の成長に伴いプロダクトが成熟してきた場面では、似たようなプロダクトが乱立する結果、尖った機能や最先端技術で差別化をするようになるため、こうした場合には先行した特許取得が重要な目的になってきます。`}</p>
    <p>{`このように、事業の成長フェーズに応じた知財活動の計画を立案するべく、まずはメドレーのミッションおよびそのミッションを実現するためのプロダクトの性質を把握する必要がありました。`}</p>
    <p>{`メドレーは「医療ヘルスケアの未来をつくる」というミッションを掲げ、インターネットテクノロジーによる医療のあり方の変革を目指す企業です。`}</p>
    <p>{`このミッションと向き合いつつ、`}<strong parentName="p">{`事業プラットフォームの成長フェーズごとに知財活動の方向性・目的を３段階にわけて設定し、成長フェーズごとに知財活動計画を立案しました`}</strong>{`。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120416.jpg",
      "alt": "20211126120416.jpg"
    }}></img>
    <p>{`例えば、プラットフォームの創設期においては新規ユーザ獲得が重要となり、使い勝手のいいシンプルな機能に開発の方向性が向いているため、独自性のある技術を特許化するというよりも、まずは守りを固めるべく知財権侵害のリスクを最小限にして法的安定性の確保に軸足を置いた知財活動を推進します。`}</p>
    <p>{`このような計画は、知財の観点で一方的にできるものではありません。`}</p>
    <p>{`知財活動の計画に際しては、開発チームを統括するマネージャにプロダクト開発の方向性をヒアリングして特許取得の判断基準を検討したり、経営層に将来の事業の方向性についてヒアリングをして長期志向で目標設定したりして、ブラッシュアップしていきました。`}</p>
    <p>{`そういった検討や議論を重ねていくうちに自然と、その企業風土や事業ミッションにあった知財活動計画というものができあがってくるのだと思います。`}</p>
    <h1>{`知財活動ロードマップで現在位置を把握しつつ定期的に軌道修正する`}</h1>
    <p>{`長期的な方向性を可視化したら次はそれをどう実行するかという実行計画が必要になってきました。`}</p>
    <p>{`そもそも自分たちは今何ができていて、何ができていないのか。またどのようなことを今後やっていかなければならないのかが不明確だよね、という話になり、それを具体的に俯瞰するための知財ロードマッピングというものを作成しました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120419.jpg",
      "alt": "20211126120419.jpg"
    }}></img>
    <p>{`縦軸に知的財産の種類、横軸に時間軸であるフェーズを設定して、知財活動の内容に過不足ないかをチェックできるようにしました。`}</p>
    <p>{`定期的に内容を見直してメドレーの事業規模にあった形に修正しながら自分達の現在位置を把握し、今後の方向性を見定めるためのツールとして有効でした。`}</p>
    <p>{`このようなロードマップがあれば、知財担当者の立場からすると、実績が可視化されるため知財活動を推進していく上での達成感もありますし、逆に知財担当者を評価する立場からしても、明確な評価基準がない中で、知財担当者の評価をする際に役に立ちます。`}</p>
    <p>{`以上のように、何もないところから他部門と関わりながら知財活動計画を立案し、ロードマップを達成していくのは、知財部門を立ち上げるという業務の面白さの１つでもあります。`}</p>
    <h1>{`初期段階から長期視点で知財素人でも回せる業務運用作りをする`}</h1>
    <p>{`1 人法務や 1 人知財担当という状況は、遅かれ早かれ属人化という問題が必ずやってきます。`}</p>
    <p>{`属人化は組織運営上、健全ではありません。`}</p>
    <p>{`そのため、`}<strong parentName="p">{`知財に関する知識がない人間でも、誰でもその業務を回すことができるような状態をつくる`}</strong>{`ために、知財部門立ち上げの初期段階から、自分が担当している業務でルーチン化できるものは全て仕組み化してドキュメントに落とし込むようにしていました。`}</p>
    <p>{`例えば「怪しい特許を見つけた場合の動き方」、「発明報奨金の決定プロセス」、「他社特許調査及び発明発掘のハイブリッド調査の進め方」などなど、ありとあらゆるプロセス業務を全てフロー図や概念図を作成し、誰か見てもわかる業務ということに留意しています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120422.jpg",
      "alt": "20211126120422.jpg"
    }}></img>
    <p>{`現状はまだ引き継ぎや新たな知財部員の登用という話がないので、わかりやすい効果を発揮しているわけではないのですが、他部門からの問い合わせがあった際は資料だけ渡せば理解してもらえるので、コミュニケーションの効率化には貢献していると思います。`}</p>
    <h1>{`どのような特許を取得すべきなのか`}</h1>
    <p>{`企業としてどのような特許を取得していくべきか、ということは「知財を最大限に活用する」という知財の出口から考えていく上で、重要な事項になります。`}</p>
    <p>{`そしてどのような特許を取得するかは、事業領域や事業ミッションに沿って設定されるべきです。`}</p>
    <p>{`メドレーは、オープンプラットフォームによる医療のあり方の変革を目指している企業なので、開発チームから上がってきたアイデアについては、`}<strong parentName="p">{`プラットフォーム事業を適切に運営していく上で必要な技術かどうか`}</strong>{`、という観点で特許化するかどうかを判断しています。`}</p>
    <p>{`例えば、最近特許を取得した`}<strong parentName="p">{`医療データの管理方法（`}<a parentName="strong" {...{
          "href": "https://www.j-platpat.inpit.go.jp/c1800/PU/JP-6921177/A0C5C2D58C02064BEB59C6825B06C3CBE4628C3B499220B9ED43E50DD8EF6E7C/15/ja"
        }}>{`特許第 6921177 号`}</a>{`）`}</strong>{`というものがあります。これは、アプリ端末から入力された患者データと、医療機関の各業務システムから入力された患者データという 2 つの類似したデータをシームレスに管理するために、両方のデータを 2 つのデータベース上で統合管理することにより、データ管理の責任分担の明確化及び厳格な情報管理を可能にするというものです。`}</p>
    <p>{`仕組みとしてはとてもシンプルなのですが、`}<strong parentName="p">{`患者の持つ端末と、医科・歯科・調剤等の各業務システムをシームレスに連携させる仕組みが、医療プラットフォームを適切に運営していく上で必要な技術だと判断`}</strong>{`したため、特許化しました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120426.jpg",
      "alt": "20211126120426.jpg"
    }}></img>
    <p>{`また、上記事例のように実際にプロダクトに実装されている技術を特許化したものだけでなく、実際にプロダクトに実装するかわからないけれども将来を見据えた先見的な特許として、`}<strong parentName="p">{`ブロックチェーンを用いた電子処方箋の管理方法（`}<a parentName="strong" {...{
          "href": "https://www.j-platpat.inpit.go.jp/c1800/PU/JP-6936763/AF400254F45C09B0BC8C9BA831F8C3CB3C41740DF9A6E4E697B15FA9348785F1/15/ja"
        }}>{`特許第 6936763 号`}</a>{`）`}</strong>{`という特許を取得しています。`}</p>
    <p>{`これは、ブロックチェーンを用いたアクセス権の管理機能として全体的に秘密状態を保ちながら電子処方箋を管理するためのアクセス制御に関する仕組みで、ブロックチェーンデータベース上において処方箋データと患者データとを紐づけて、医師端末からの患者レコードへの一時的なアクセス権限を付与し、会計終了時に患者端末の操作に応じて医師端末へのアクセス権限を剥奪するというものです。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120429.jpg",
      "alt": "20211126120429.jpg"
    }}></img>
    <p>{`このような特許を取得した背景には、`}<strong parentName="p">{`マーケットを独占して権利主張をするためというよりも、メドレーが考える未来のプロダクトビジョンであったり、開発部門の先見的な創作活動を対外的に発信したい`}</strong>{`、という思いがあります。`}</p>
    <p>{`メドレーは、顧客利用率の高いプロダクト、機能拡張性の高いプロダクトの開発を推進しているため、`}<strong parentName="p">{`独創的で最新技術を取り入れた尖った機能を目指すというよりはユーザにとって使いやすいシンプルさが多く取り入れられています。`}</strong>{`また、それだけではなく、前述したブロックチェーンと電子処方箋との組み合わせ技術の先見的な特許取得のように、10 年先を見据えた未来のプロダクトのあり方についても日々追求しています。`}</p>
    <h1>{`特許を身近に感じてもらうためのユニークな話も取り入れて社内に啓蒙する`}</h1>
    <p>{`いい特許を生み出すためには地道な社内の啓蒙活動も重要です。`}</p>
    <p>{`メドレーでは、開発チーム間の技術格差の是正や、技術情報の共有による活性化を目的として、エンジニアが日々の業務で扱っている技術や取り組みについて共有するテックランチという勉強会が定期的に開催されています。`}</p>
    <p>{`先日そのテックランチで、社内のエンジニアの方達に少しでも特許を身近に感じてもらおうと、「特許の頭体操」というコーナーを設けて、実際に頭を使って体験してもらいました。`}</p>
    <p>{`下の例では、おたまとスプーンとの違いを考えようというお題を通して、ある物の特徴を把握する際に、`}<strong parentName="p">{`「違い」から「もの」を観察すると、その物の特徴が浮き出てくる`}</strong>{`、というメッセージが含まれています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120432.jpg",
      "alt": "20211126120432.jpg"
    }}></img>
    <p>{`おたまもスプーンも対象物をすくい上げるという機能においては共通しているのですが、おたまの先端のお皿部分とスプーンの先端のお皿部分とは、その形状が異なります。スプーンのお皿部分は楕円形ですが、おたまのお皿部分は円形になっています。`}</p>
    <p>{`つまり、おたまの特徴は、特許的にいうと「その先端にあるお皿部分が円形の開口を有した半球状で、対象物をすくうための所定の深さを有している」ということになります。`}</p>
    <p>{`この辺はちょっと固苦しい表現が続いたせいもあり、「難しい...」というコメントをもらいました。馴染みのない人にとってはただただ面倒な作業だと思います。`}</p>
    <p>{`しかし、ここで言いたかったのは、`}<strong parentName="p">{`これはあくまで構造物についての話であって、ソフトウェアの特徴を把握するということはそんなに難しいことではないのだよ`}</strong>{`、ということでした。`}</p>
    <p>{`ソフトウェアの場合は、ちょっとした機能を追加すれば、それが従来のソフトとは異なるものとなり、比較的簡単に特許になってしまうケースが少なくありません。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120842.jpg",
      "alt": "20211126120842.jpg"
    }}></img>
    <p>{`つまり、エンジニアの方は日々新しい機能を実装すべく開発業務を行っているわけなので、`}<strong parentName="p">{`知財の観点から言うと、極端なことを言えば日々発明をしている`}</strong>{`ということになります。当然特許になるかどうかは別の話になりますが、日々の開発業務で自分が発明をしていることに気づいていない、という状況が多分にあるということです。`}</p>
    <p>{`さらに、`}<strong parentName="p">{`知財担当がこれに気づかなければ、日の目を見ることない埋蔵知財が量産される`}</strong>{`ということになってしまいます。`}</p>
    <p>{`では、どのようにしてエンジニアの方が自ら発明をしていることに気づいていないものを発掘し、社内の知的財産として認定していくのか、というのが次の話になります。`}</p>
    <h1>{`面白いアイデアは雑談から生まれる`}</h1>
    <p>{`日々の開発業務の延長で生まれてくる機能や技術を特許化するという活動が基本であることは間違いないのですが、実際にプロダクトに実装するかどうか不確定要素を多く含むアイデアを特許化するという活動は、エンジニアの開発成果物を知的財産として見える化することで、エンジニアの成果が報われる土壌を作るという意味においては大切な活動になってきます。`}</p>
    <p>{`ただ、どの企業知財部でも発明発掘業務はされていると思いますし、「アイデアは雑談から生まれる」ということは既に周知の事実かもしれません。また、知財活動として特段新しいことをしているわけではないのですが、実際にそれを実行する際の心構えであったり、具体的に気をつけていることについて、事例を通してご紹介したいと思います。`}</p>
    <p>{`私は、`}<strong parentName="p">{`エンジニアの方というのは、アイデアの卵をもっている`}</strong>{`という前提に立ってエンジニアの方とコミュニケーションをとるようにしています。`}</p>
    <p>{`そうすると、「実はアイデアありますよね、なんで言ってくれないんですか〜。」という具合にカジュアルな会話をスタートすることができ、スムーズにブレストを進めることができたりします。大したことではないのですが、`}<strong parentName="p">{`意外とこういう心がけが円滑なコミュニケーションを生み出すきっかけになっている`}</strong>{`かもしれません。`}</p>
    <p>{`一方で、エンジニアの方は暇ではありません。`}</p>
    <p>{`日々の開発では、既存機能の修正や新規機能の開発などの案件に追われるため、頭の中にあるアイデアの卵も埋もれてしまいがちです。`}</p>
    <p>{`以上のことを踏まえ、エンジニアからアイデアを出してもらうためのブレスト MTG では主に以下の点に気をつけています。`}</p>
    <ul>
      <li parentName="ul">{`エンジニアの方の負担にならないように短時間に設定すること（長くて 30 分）`}</li>
      <li parentName="ul">{`カジュアルに話しやすい雑談ベースの雰囲気にすること`}</li>
      <li parentName="ul">{`そのために少人数で行うこと`}</li>
      <li parentName="ul">{`出てきたアイデアを楽しむこと`}</li>
    </ul>
    <p>{`実際に下図に示すような雑談ベースの会話から、「診療方式を患者と医療機関との間の距離に応じて、対面診療かオンライン診療かを自動的に切り替える」というアイデアが生まれ、実際に特許出願につながりました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120918.jpg",
      "alt": "20211126120918.jpg"
    }}></img>
    <p>{`この時にエンジニアの方々からは「とても面白い！」とか「またやりたい！」という前向きなコメントをもらえて、カジュアルベースの雑談効果を実感しました。`}</p>
    <p>{`このようなブレストを通して一番大きな気づきというのは、エンジニアの皆さん、本当によくプロダクトについて考えているということ。`}</p>
    <p>{`エンジニアの方々の創意工夫が全て知的財産「権」になるわけではないのですが、知的財産であることには違いありません。`}</p>
    <p>{`これからは、そういった`}<strong parentName="p">{`エンジニアの方の頭の中に埋もれがちな創意・工夫というものが報われるような土壌作りを、知的財産というツールを使って構築していきたい`}</strong>{`と考えています。`}</p>
    <h1>{`このブログも「特許を対外的にどう見せるか」という実験の１つ`}</h1>
    <p>{`ここまでご紹介してきた内容は、知財計画を立てて、立てた計画に沿って知財業務を運用し、運用していく中でアイデア発掘して知財化する、という話にとどまってましたが、実際に取得した知財権をどのように活用して企業価値の向上につなげるのか、ということがこれからの知財部門にとっての重要なミッションになると考えています。`}</p>
    <p>{`従来は、特許権の独占排他権という性質を使って参入障壁として活用したり、模倣の抑止に活用されるのが常識だったと思いますが、メドレーではこれまでにない新たな活用方法を模索しています。`}</p>
    <p>{`実はこのブログもそうなのですが、特許を対外的にどうみせるか、ということの１つの実験です。`}</p>
    <p>{`どういうことかというと、ここまでご紹介してきた`}<strong parentName="p">{`知財活動自体も人の知的活動によって生み出された知的財産であり、このようにブログで発信しなければ単なる埋蔵知財になってしまいます`}</strong>{`。`}</p>
    <p>{`例えば、特許取得についてもただ特許を取ったという事実を公表するのではなく、どうしてそのような特許を取得してどのように活用しているのか、またそこに関連したメンバーは誰でどのような検討があったのか、というストーリーメイキングをすると、特許というものを中心とした１つのドラマが浮き上がってきます。`}</p>
    <p>{`今回は知財活動のご紹介という趣旨で、特許を中心とした内容になっているため、特許に関心がない人にはあまりささらないコンテンツになっているかもしれませんが、例えば特許と広報との掛け算でストーリーメイキングをすれば`}<strong parentName="p">{`特許に関心がある人だけでなく、広報に関心がある人にも情報がリーチすることになります`}</strong>{`。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20211126/20211126120933.jpg",
      "alt": "20211126120933.jpg"
    }}></img>
    <p>{`これは`}<strong parentName="p">{`特許というものが、人材・業務ノウハウ・広報・採用活動等と同列に知的財産である`}</strong>{`がゆえになせるものです。これからは、知的財産＝特許、商標という視点ではなく、知財＝目に見えない創造的な活動という捉え方をした上で、企業価値に貢献していく必要があります。`}</p>
    <p>{`知財部門が、採用、IR、広報、開発、社内 IT といった企業内にある多数の部門とコラボレーションすることでこれまで見たことのない新しい知財活用の形を模索し、`}<strong parentName="p">{`知財を最大活用することによって企業価値を向上させていきたい`}</strong>{`と考えています。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`ここまで、知財活動の一部をご紹介させていただきましたが、これが知財活動の進め方として正解というわけではなく、企業風土や事業領域によって、知財活動のあり方は異なります。そして、そこを考えながら知財活動を推進していくことが仕事の面白さであり醍醐味とも言えます。新しい知財部のあり方や、新しい知的財産の活用方法を検証しながらも、建設的かつ柔軟に日々の業務を進め、メドレーの事業をしっかりとバックアップしていきたいと考えています。少しでも当社の知財活動が参考になれば幸いです。最後までお付き合い頂きありがとうございました！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      